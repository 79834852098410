<template>
  <div>
    <div class="row">
      <div class="col-6" style="margin-top: 30px;">
        <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
      </div>
      <div class="col-6" style="margin-top: 30px;">
        <c-dept type="search" name="deptCd" v-model="searchParam.deptCd" />
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-text
          label="사용자명"
          name="userName"
          v-model="searchParam.userName">
        </c-text>
      </div> -->
      <div class="col-12" style="margin-top: -30px;">
        <c-table
          ref="userTable"
          title="사용자"
          class="apprUserTable"
          :isTitle="true"
          :columns="grid.columns"
          :data="grid.data"
          gridHeight="260px"
          :columnSetting="false"
          :isFullScreen="false"
          :hideHeader="true"
          :hideBottom="true"
          :filtering="true"
          selection="multiple"
          rowKey="userId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-12" style="margin: auto 0; line-height: 30px; text-align:center;">
        <c-btn label="확인" icon="arrow_downward" color="blue-grey-4" @btnClicked="addApprLine('AKC0000004')" />&nbsp;
        <c-btn label="합의" icon="arrow_downward" color="green-7" @btnClicked="addApprLine('AKC0000002')" />&nbsp;
        <c-btn label="결재" icon="arrow_downward" color="blue-7" @btnClicked="addApprLine('AKC0000003')" />&nbsp;
        <c-btn label="회람" icon="arrow_downward" color="blue-grey-4" @btnClicked="addApprLine('AKC0000009')" />
      </div>
      <div class="col-12">
        <div class="appr-line-detail-layer">
          <c-card title="결재선" class="cardClassDetailFormLine">
            <template slot="card-detail">
              <div class="col-12">
                <draggable
                  tag="ul"
                  :list="linegrid.data"
                  class="list-group"
                  handle=".handle"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="drag = false"
                  @change="moveApprLine"
                  :move="checkMove"
                >
                  <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <li
                      class="list-group-item"
                      v-for="(element, idx) in linegrid.data"
                      :key="element.approvalUserId + element.approvalKindCd"
                      :class="{'linehandle': element.draggable}"
                    >
                      <i class="material-icons linehandley handle" v-if="element.draggable">reorder</i>
                      <i class="material-icons linehandlex no-move" v-if="!element.draggable">reorder</i>
                      <div class="linetext linespan1" :class="getLineKindColor(element.approvalKindCd)">{{ element.approvalKindName }} </div>
                      <div class="linetext linespan2">{{ element.deptName }}</div>
                      <div class="linetext linespan3">{{ element.approvalUserName }}</div>
                      <i class="material-icons lineclose" v-if="element.draggable" @click="removeAt(idx)">clear</i>
                    </li>
                  </transition-group>
                </draggable>
              </div>
            </template>
          </c-card>
          <c-card title="회람" class="cardClassDetailFormLine">
            <template slot="card-detail">
              <div class="col-12">
                <draggable
                  tag="ul"
                  :list="linegrid2.data"
                  class="list-group"
                  handle=".handle"
                  v-bind="dragOptions"
                >
                  <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <li
                      class="list-group-item"
                      v-for="(element, idx) in linegrid2.data"
                      :key="element.approvalUserId + element.approvalKindCd"
                      :class="{'linehandle': element.draggable}"
                    >
                      <i class="material-icons linehandlex no-move">reorder</i>
                      <div class="linetext linespan1" :class="getLineKindColor(element.approvalKindCd)">{{ element.approvalKindName }} </div>
                      <div class="linetext linespan2">{{ element.deptName }}</div>
                      <div class="linetext linespan3">{{ element.approvalUserName }}</div>
                      <i class="material-icons lineclose" @click="delApprLine(idx)">clear</i>
                    </li>
                  </transition-group>
                </draggable>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </div>
    <!-- 버튼 영역 -->
    <div class="search-box-btn">
      <q-btn-group outline >
        <c-btn label="변경완료" icon="save" @btnClicked="select" />
      </q-btn-group>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import draggable from 'vuedraggable';
export default {
  components: {
      draggable,
  },
  name: 'appr-line-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          apprlinedata: [],
          apprlinedata2: [],
          approvalLineGrp: 1,
        }
      },
    },
  },
  data() {
    return {
      dragging: false,
      drag: false,
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '300px'
      },
      linegrid: {
        data: [],
      },
      linegrid2: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            label: '성명',
            align: 'center',
            sortable: false
          },
        ],
        data: [],
        height: '200px'
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        userName: '',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    moveApprLine() {
      let _cnt = 1;
      this.$_.forEach(this.linegrid.data, item => {
        item.approvalLineNo = _cnt;
        _cnt++;
      })
    },
    checkMove: function(e) {
      if (e.draggedContext.futureIndex === 0) {
        return false;
      }
    },
    init() {
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.user.list.url
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      this.getList();
      this.getApprLine();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getApprLine() {
      this.$_.extend(this.linegrid.data, this.popupParam.apprlinedata);
      this.$_.extend(this.linegrid2.data, this.popupParam.apprlinedata2);
    },
    select() {
      let returnLineData = {
        apprlinedata: this.linegrid.data,
        apprlinedata2: this.linegrid2.data,
      };
      this.$emit('closePopup', returnLineData);
    },
    addApprLine(_kindCd) {
      let selectData = this.$refs['userTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '사용자를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (_kindCd == 'AKC0000009') { // 회람자 추가
          this.$_.forEach(selectData, item => {
            if (this.linegrid2.data.map(function(e) {return e.approvalUserId;}).indexOf(item.userId) < 0) {
              this.linegrid2.data.push({
                approvalKindCd: _kindCd,
                approvalKindName: this.getApprKindName(_kindCd),
                approvalUserId: item.userId,
                approvalUserName: item.userName,
                deptName: item.deptName,
                approvalLineNo: this.linegrid2.data.length + 1001,
                sysApprovalRequestId: '',
                approvalStatusName: '',
                approvalDt: null,
                approvalStatusCd: '',
                approvalLineGrp: this.popupParam.approvalLineGrp,
              })
            }
          });
        } else {
          this.$_.forEach(selectData, item => {
            // 목록에 중복된 사용자 제외 (결재유형+사용자가 동일하지 않은 사용자만 추가 가능)
            if (this.linegrid.data.map(function(e) {return e.approvalUserId + e.approvalKindCd;}).indexOf(item.userId + _kindCd) < 0) {
              this.linegrid.data.push({
                approvalKindCd: _kindCd,
                approvalKindName: this.getApprKindName(_kindCd),
                approvalUserId: item.userId,
                approvalUserName: item.userName,
                deptName: item.deptName,
                approvalLineNo: this.linegrid.data.length + 1,
                draggable: true,
                sysApprovalRequestId: '',
                approvalDt: null,
                approvalStatusCd: '',
                approvalLineGrp: this.popupParam.approvalLineGrp,
              })
            }
          });
        }
        this.$refs['userTable'].$refs['compo-table'].clearSelection();
      }
    },
    removeAt(idx) {
      this.linegrid.data.splice(idx, 1);
      let _cnt = 1;
      this.$_.forEach(this.linegrid.data, item => {
        item.approvalLineNo = _cnt;
        _cnt++;
      })
    },
    delApprLine(idx) {
      this.linegrid2.data.splice(idx, 1);
    },
    getApprKindName(_cd) {
      let name = '';
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      switch(_cd) {
        case 'AKC0000010':
          name = '재상신';
          break;
        case 'AKC0000001':
          name = '상신';
          break;
        case 'AKC0000002':
          name = '합의';
          break;
        case 'AKC0000003':
          name = '결재';
          break;
        case 'AKC0000004':
          name = '확인';
          break;
        case 'AKC0000009':
          name = '회람';
          break;
      }
      return name;
    },
    getLineKindColor(_cd) {
      let color = '';
      // AKC0000001 : 상신, AKC0000002 : 합의, AKC0000003 : 결재, AKC0000004 : 확인, AKC0000009 : 회람, AKC0000010 : 재상신
      switch(_cd) {
        case 'AKC0000010':
        case 'AKC0000001':
          color = 'blue-grey-4';
          break;
        case 'AKC0000002':
          color = 'green-7';
          break;
        case 'AKC0000003':
          color = 'blue-7';
          break;
        case 'AKC0000004':
          color = 'blue-grey-4';
          break;
      }
      return 'text-' + color;
    }
  },
};
</script>

<style scoped>
.linehandley {
  float: left;
  margin-left: -10px;
  padding-top: 4px;
  padding-right: 10px;
  cursor: move;
}
.linehandlex {
  float: left;
  margin-left: -10px;
  padding-top: 4px;
  padding-right: 10px;
  opacity: 0.5;
}
.lineclose {
  float: right;
  padding-top: 0px;
  font-size: 20px;
  margin-right: -10px;
  cursor: pointer;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.3;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.linespan1 {
  min-width: 50px !important;
  text-align: left;
  font-weight: 600;
}
.linespan2 {
  min-width: 120px !important;
  text-align: left;
}
.linetext {
  display: inline-block;
}
.list-group-item {
  padding: 0.4rem  1.25rem !important;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 0.8rem;
}
.appr-line-detail-layer {
  margin: 0px 10px 50px 10px !important;
  padding: 5px !important;
  border: 2px solid #7b1fa2;
  border-radius: 14px;
}
</style>