var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6", staticStyle: { "margin-top": "30px" } },
        [
          _c("c-plant", {
            attrs: { type: "search", name: "plantCd" },
            model: {
              value: _vm.searchParam.plantCd,
              callback: function ($$v) {
                _vm.$set(_vm.searchParam, "plantCd", $$v)
              },
              expression: "searchParam.plantCd",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-6", staticStyle: { "margin-top": "30px" } },
        [
          _c("c-dept", {
            attrs: { type: "search", name: "deptCd" },
            model: {
              value: _vm.searchParam.deptCd,
              callback: function ($$v) {
                _vm.$set(_vm.searchParam, "deptCd", $$v)
              },
              expression: "searchParam.deptCd",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12", staticStyle: { "margin-top": "-30px" } },
        [
          _c(
            "c-table",
            {
              ref: "userTable",
              staticClass: "apprUserTable",
              attrs: {
                title: "사용자",
                isTitle: true,
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                gridHeight: "260px",
                columnSetting: false,
                isFullScreen: false,
                hideHeader: true,
                hideBottom: true,
                filtering: true,
                selection: "multiple",
                rowKey: "userId",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "검색", icon: "search" },
                        on: { btnClicked: _vm.getList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "col-12",
          staticStyle: {
            margin: "auto 0",
            "line-height": "30px",
            "text-align": "center",
          },
        },
        [
          _c("c-btn", {
            attrs: {
              label: "확인",
              icon: "arrow_downward",
              color: "blue-grey-4",
            },
            on: {
              btnClicked: function ($event) {
                return _vm.addApprLine("AKC0000004")
              },
            },
          }),
          _vm._v("  "),
          _c("c-btn", {
            attrs: { label: "합의", icon: "arrow_downward", color: "green-7" },
            on: {
              btnClicked: function ($event) {
                return _vm.addApprLine("AKC0000002")
              },
            },
          }),
          _vm._v("  "),
          _c("c-btn", {
            attrs: { label: "결재", icon: "arrow_downward", color: "blue-7" },
            on: {
              btnClicked: function ($event) {
                return _vm.addApprLine("AKC0000003")
              },
            },
          }),
          _vm._v("  "),
          _c("c-btn", {
            attrs: {
              label: "회람",
              icon: "arrow_downward",
              color: "blue-grey-4",
            },
            on: {
              btnClicked: function ($event) {
                return _vm.addApprLine("AKC0000009")
              },
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "appr-line-detail-layer" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailFormLine",
                attrs: { title: "결재선" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "list-group",
                            attrs: {
                              tag: "ul",
                              list: _vm.linegrid.data,
                              handle: ".handle",
                              move: _vm.checkMove,
                            },
                            on: {
                              start: function ($event) {
                                _vm.drag = true
                              },
                              end: function ($event) {
                                _vm.drag = false
                              },
                              change: _vm.moveApprLine,
                            },
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        [
                          _c(
                            "transition-group",
                            {
                              attrs: {
                                type: "transition",
                                name: !_vm.drag ? "flip-list" : null,
                              },
                            },
                            _vm._l(_vm.linegrid.data, function (element, idx) {
                              return _c(
                                "li",
                                {
                                  key:
                                    element.approvalUserId +
                                    element.approvalKindCd,
                                  staticClass: "list-group-item",
                                  class: { linehandle: element.draggable },
                                },
                                [
                                  element.draggable
                                    ? _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons linehandley handle",
                                        },
                                        [_vm._v("reorder")]
                                      )
                                    : _vm._e(),
                                  !element.draggable
                                    ? _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons linehandlex no-move",
                                        },
                                        [_vm._v("reorder")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "linetext linespan1",
                                      class: _vm.getLineKindColor(
                                        element.approvalKindCd
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(element.approvalKindName) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "linetext linespan2" },
                                    [_vm._v(_vm._s(element.deptName))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "linetext linespan3" },
                                    [_vm._v(_vm._s(element.approvalUserName))]
                                  ),
                                  element.draggable
                                    ? _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons lineclose",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAt(idx)
                                            },
                                          },
                                        },
                                        [_vm._v("clear")]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailFormLine",
                attrs: { title: "회람" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "draggable",
                        _vm._b(
                          {
                            staticClass: "list-group",
                            attrs: {
                              tag: "ul",
                              list: _vm.linegrid2.data,
                              handle: ".handle",
                            },
                          },
                          "draggable",
                          _vm.dragOptions,
                          false
                        ),
                        [
                          _c(
                            "transition-group",
                            {
                              attrs: {
                                type: "transition",
                                name: !_vm.drag ? "flip-list" : null,
                              },
                            },
                            _vm._l(_vm.linegrid2.data, function (element, idx) {
                              return _c(
                                "li",
                                {
                                  key:
                                    element.approvalUserId +
                                    element.approvalKindCd,
                                  staticClass: "list-group-item",
                                  class: { linehandle: element.draggable },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons linehandlex no-move",
                                    },
                                    [_vm._v("reorder")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "linetext linespan1",
                                      class: _vm.getLineKindColor(
                                        element.approvalKindCd
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(element.approvalKindName) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "linetext linespan2" },
                                    [_vm._v(_vm._s(element.deptName))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "linetext linespan3" },
                                    [_vm._v(_vm._s(element.approvalUserName))]
                                  ),
                                  _c(
                                    "i",
                                    {
                                      staticClass: "material-icons lineclose",
                                      on: {
                                        click: function ($event) {
                                          return _vm.delApprLine(idx)
                                        },
                                      },
                                    },
                                    [_vm._v("clear")]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "search-box-btn" },
      [
        _c(
          "q-btn-group",
          { attrs: { outline: "" } },
          [
            _c("c-btn", {
              attrs: { label: "변경완료", icon: "save" },
              on: { btnClicked: _vm.select },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }